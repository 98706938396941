import { number, shape, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getModuleForDigibook, getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import DrawerOptionsMenu from '../DrawerOptionsMenu';
import OptionsMenuActions from './OptionsMenuActions';
import UnlicenseSlideSetListItem from './UnlicensedSlideSetListItem';
import SlideSetInfo from './SlideSetInfo';

function LicensedSlideSetListItem({ slideSet }) {
  const { id, name } = slideSet;

  const analytics = useAnalytics();
  const moduleId = useSelector(getCurrentModuleId);

  const handleAnalytics = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: moduleId,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });
  };

  return (
    <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
      <div className="pbb-list__link-wrap">
        <a
          onClick={handleAnalytics}
          className="pbb-list__link pbb-flex-wrap"
          title={name}
          href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SlideSetInfo slideSet={slideSet} />
        </a>
        <div className="pbb-list__actions">
          <DrawerOptionsMenu>
            <OptionsMenuActions slideSet={slideSet} />
          </DrawerOptionsMenu>
        </div>
      </div>
    </li>
  );
}

const SlideSetListItem = ({ slideSet }) => {
  const module = useSelector(getModuleForDigibook);

  if (module.licenses.hasDocumentLicenseForTeacher) {
    return <LicensedSlideSetListItem slideSet={slideSet} />;
  }

  return <UnlicenseSlideSetListItem slideSet={slideSet} />;
};

SlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
};

LicensedSlideSetListItem.propTypes = SlideSetListItem.propTypes;

export default SlideSetListItem;
